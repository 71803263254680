<template>
    <div class="planned-sales-calender-table" v-if="data">

        <div class="filter-container" v-if="!offerData">
            <div class="flex">
                <div class="mr-2">
                    <label>Zeitraum <b>von</b> (Monat):</label>
                    <mg-select v-model="filterParams.month_start" :options="monthsOptions" name="month_start" data-vv-as="Zeitraum von (Monat)"
                               placeholder="Monat" track-by="id" label="name">
                    </mg-select>
                </div>
                <div class="mr-5">
                    <label>Zeitraum <b>von</b> (Jahr):</label>
                    <mg-select v-model="filterParams.year_start" :options="yearsOptions" name="year_start" data-vv-as="Zeitraum von (Jahr)"
                               placeholder="Jahr" track-by="id" label="id">
                    </mg-select>
                </div>
                <div class="mr-2">
                    <label>Zeitraum <b>bis</b> (Monat):</label>
                    <mg-select v-model="filterParams.month_end" :options="monthsOptions" name="month_end" data-vv-as="Zeitraum bis (Monat)"
                               placeholder="Monat" track-by="id" label="name">
                    </mg-select>
                </div>
                <div class="mr-5">
                    <label>Zeitraum <b>bis</b> (Jahr):</label>
                    <mg-select v-model="filterParams.year_end" :options="yearsOptions" name="year_end" data-vv-as="Zeitraum bis (Jahr)"
                               placeholder="Jahr" track-by="id" label="id">
                    </mg-select>
                </div>
                <div class="mr-2">
                    <label>Kunde auswählen:</label>
                    <mg-select v-model="filterParams.client_id" :options="clients" name="client"
                               placeholder="Kunde auswählen"
                               track-by="id" label="company_name">
                    </mg-select>

                </div>
            </div>

        </div>

        <div id="chart">
            <vue-apex-charts  type="area" height="350" :options="chartOptions" :series="series"></vue-apex-charts>
        </div>

        <div class="flex mt-2 mb-2">
            <div class="cell first">
                <div class="month"></div>
                <div class="soll">
                    Plan
                </div>
                <div class="ist">
                    Ist
                </div>
                <div class="difference">
                    Differenz
                </div>
            </div>
            <div v-for="(column, index) in data.months" class="cell month" v-bind:class="[column.currentMonth === true ? 'is-active' : '']">
                <div class="month">
                    {{index}}
                </div>
                <div class="soll">
                    {{column.soll|currency}}
                </div>
                <div class="ist">
                    {{column.ist|currency}}
                </div>
                <div class="difference">
                    <div class="color-red" v-if="column.difference < 0">
                        {{column.difference|currency}}
                    </div>
                    <div class="color-green" v-if="column.difference > 0">
                        {{column.difference|currency}}
                    </div>

                </div>
            </div>
        </div>
        <div class="sum flex">
            <div class="color-red" v-if="data.sum_total < 0">
                Aktueller Stand: {{data.sum_total|currency}} hinter Planumsatz
            </div>
            <div class="color-green" v-if="data.sum_total > 0">
                Aktueller Stand: {{data.sum_total|currency}} über Planumsatz
            </div>
        </div>

    </div>
</template>

<script>
    import ApiService from "../../api";
    import MgSelect from "../../components/mg-select/MgSelect";
    import staticOptions from "../../mixins/static/options";
    import * as qs from "qs";
    import moment from 'moment';
    import PriceHelper from "../../mixins/helper/price.helper";
    import {mapGetters} from "vuex";

    import VueApexCharts from 'vue-apexcharts'


    export default {
        name: "planned-sales-calender-table",
        components: {
            MgSelect,
            VueApexCharts
        },
        props: {
            offerData: {
                default: null
            },
        },
        data() {
            return {
                data: [],
                clients:[],
                filterParams: {
                    "month_start": null,
                    "year_start": null,
                    "month_end": null,
                    "year_end": null,
                    "client_id":null
                },

                /*
                Chart
                 */
                series: [{
                    name: 'Plan-Umsatz',
                    data: []
                }, {
                    name: 'Ist-Umsatz',
                    data: []
                }],
                chartOptions: {
                    chart: {
                        height: 250,
                        type: 'area'
                    },
                    tooltip: {
                    },
                    dataLabels: {
                        enabled: false
                    },
                    stroke: {
                        curve: 'smooth'
                    },
                    labels: ["2"],
                    xaxis: {
                        type: 'string',
                        categories: [],

                    },

                },

            }
        },
        created() {
            this.fetchData()
        },
        computed: {
            ...mapGetters(['userHasPermission', 'userId', 'user']),

            monthsOptions() {
                return staticOptions.months;
            },
            requestParams() {
                return this.filterParams;
            },
            yearsOptions() {

                var date = new Date,
                    years = [],
                    year = 2017;

                for (var i = year; i < date.getFullYear()+2; i++) {
                    years.push({id:i.toString()});
                }

                return years;
            },
        },
        methods: {
            fetchData() {
                this.$vs.loading();
                if(this.offerData) {
                    this.fetchOfferPlannedSalesCalender().then(response => {
                        this.prepareChart();
                    })
                } else {
                    this.fillFilterDataIfnotSet()
                    this.fetchClients()
                    this.fetchPlannedSalesCalender().then(response => {
                        this.prepareChart();
                    })
                }
                this.$vs.loading.close();
            },
            prepareChart(){
                if (!this.data) {
                    return;
                }

                this.series[0].data = [];
                this.series[1].data = [];
                this.chartOptions.labels = [];
                var obj = [];
                for (var month in this.data.months) {
                    this.series[0].data.push(this.data.months[month].soll.toFixed(2))
                    this.series[1].data.push(this.data.months[month].ist.toFixed(2))
                    obj.push(month) ;
                }
                this.chartOptions = {
                    labels: obj
                };

            },
            fetchOfferPlannedSalesCalender() {

                return ApiService.get('order_planned_sales/calender/'+this.$route.params.id).then(response => {
                    this.data = response.data.result;
                    this.$vs.loading.close()
                })
            },
            fetchPlannedSalesCalender() {

                this.fillFilterDataIfnotSet()

                return ApiService.get('order_planned_sales/calender', {
                    params: this.requestParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                      this.data = response.data.result;
                    this.$vs.loading.close()
                })

            },

            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
            fillFilterDataIfnotSet() {
                if(!this.filterParams.month_start) {
                    this.filterParams.month_start = moment().subtract(4,'months').format('MM')
                }
                if(!this.filterParams.year_start) {
                    this.filterParams.year_start = moment().subtract(4,'months').format('YYYY')
                }
                if(!this.filterParams.month_end) {
                    this.filterParams.month_end = moment().add(8,'months').format('MM');
                }
                if(!this.filterParams.year_end) {
                    this.filterParams.year_end = moment().add(8,'months').format('YYYY');
                }
            },
            fetchClients(){
                if (this.clients.length >= 1) {
                    return true;
                }
                ApiService.get(`clients`)
                    .then(response => {
                        if (response.status == 200) {
                            this.clients = response.data.result;
                        }
                    })
                    .catch((error) => {
                    });
            },

        },
        watch: {
            filterParams: {
                deep: true,
                handler: 'fetchData'
            }
        }
    }
</script>

<style lang="scss">
    .planned-sales-calender-table {
        margin-top: 50px;
        .cell.month.is-active {
            background: #f8f8f8!important;
        }
        .cell {
            box-sizing: border-box;
            flex-grow: 1;
            width: 100%;

            overflow: hidden;
            list-style: none;
            > div {
                padding: 5px 15px;
                min-height: 35px;
            }
            >.difference {
                border-top: 2px solid #e2e2e2;

                .color-red {
                    color:#e24733;;
                }
                .color-green {
                    color:green
                }
            }

            .month {
                font-weight: 500;
                background: #f8f8f8;
            }
            &.first {
                font-weight: 500;
                border-right: 2px solid #e2e2e2;
                background: #f8f8f8;
            }
        }
        .sum {
            margin-top: 40px;
            font-size: 20px;
            font-weight: bold;
            justify-content: end;
            .color-red {
                color:#e24733;;
            }
            .color-green {
                color:green
            }
        }



    }

</style>
