<template>
    <div class="planned-sales-modal">
        <vs-popup class="planned-sales-modal--popup" title="Neuer Planumsatz hinzufügen" @close="onClose"
                  :active="visible">
            <div v-show="data">

                <div class="vx-row mb-2 p-4">
                    <div class="vx-col  w-full mb-0">

                        <vs-row>
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                                <label>Kunde:</label>
                            </vs-col>
                            <vs-col  vs-w="9">
                                <mg-select v-model="data.client_id" :options="clients" name="clients" data-vv-as="Kunden"
                                           placeholder="Optional: Kunde auswählen" track-by="id" label="id" @select="fetchOffers"
                                           :custom-label="customClientLabel" v-validate="'required'" :disabled="disabledClientandOffer">
                                    <template slot="singleLabel" slot-scope="{ option }">
                                        <strong>{{ option.company_name }}</strong>
                                    </template>

                                    <template slot="option" slot-scope="props">
                                        <span>
                                            {{props.option.company_name}} <small>(Kd. Nr.: {{props.option.customer_number}})</small></span>
                                    </template>
                                </mg-select>
                                <span class="text-danger text-sm" v-if="errors.has('clients')">Dieses Feld ist ein Pflichtfeld</span>
                            </vs-col>
                        </vs-row>


                        <vs-row class="mt-4">
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3" v-if="offers">
                                <label>Angebot:</label>
                            </vs-col>
                            <vs-col  vs-w="9">
                                <mg-select v-model="data.order_id" :options="offers"
                                           placeholder="Optional: Angebot auswählen" track-by="id" label="id"
                                           :custom-label="customOfferLabel" :disabled="disabledClientandOffer">
                                    <template slot="singleLabel" slot-scope="{ option }">
                                        <strong>Angebot {{ option.invoice_number }} </strong><small>{{option.title}}</small>
                                    </template>

                                    <template slot="option" slot-scope="props">
                                    <span>
                                        {{props.option.invoice_number}} <small>({{props.option.title}} - {{formatDate(props.option.order_date)}})</small></span>
                                    </template>
                                </mg-select>

                            </vs-col>
                        </vs-row>

                        <vs-row class="mt-4" v-if="!plannedSalesId">
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                                <label>Typ:</label>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                                <vs-row>
                                    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
                                        <vs-radio v-model="isInterval" vs-name="type" vs-value="0">Einmalig</vs-radio>
                                    </vs-col>
                                    <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="6">
                                        <vs-radio v-model="isInterval" vs-name="type" vs-value="1">Monatlich</vs-radio>
                                    </vs-col>
                                </vs-row>
                            </vs-col>
                        </vs-row>

                        <vs-row class="mt-4">
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                                <label>Title:</label>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                                <vs-input v-model="data.title" placeholder="Kurze Beschreibung / Matchcode / Title"
                                          name="title"
                                          class="mb-2 w-full"
                                />
                                <span class="text-danger text-sm" v-if="errors.has('title')">Dieses Feld ist ein Pflichtfeld</span>
                            </vs-col>
                        </vs-row>

                        <vs-row class="mt-4">
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                                <label><span v-if="data.type == 2">Monatliche </span>Summe:</label>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                                <vs-input v-model="data.sum_netto" placeholder="Summe"
                                          name="sum_netto"
                                          class="mb-2 w-full"
                                          v-currency="{
                                              currency: 'EUR',
                                              locale: 'de',
                                              autoDecimalMode: false,
                                              decimalLength: 2,
                                              min: 0,
                                     }"
                                />
                                <span class="text-danger text-sm" v-if="errors.has('sum_netto')">Dieses Feld ist ein Pflichtfeld</span>
                            </vs-col>
                        </vs-row>

                        <vs-row class="mt-4">
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                                <label>Zeitraum<span v-if="isInterval == true || isInterval == '1'"> von</span>:</label>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                                <vs-row>
                                    <vs-col vs-w="6">
                                        <mg-select v-model="data.date_month_from" :options="monthsOptions" name="date_month_from" data-vv-as="Zeitraum von (Monat)"
                                                   placeholder="Monat" track-by="id" label="name"
                                                   v-validate="'required'">
                                        </mg-select>
                                        <span class="text-danger text-sm" v-if="errors.has('date_month_from')">Dieses Feld ist ein Pflichtfeld</span>
                                    </vs-col>
                                    <vs-col  vs-w="6" class="pl-4">
                                        <mg-select v-model="data.date_year_from" :options="yearsOptions" name="date_year_from" data-vv-as="Zeitraum von (Jahr)"
                                                   placeholder="Jahr" track-by="id" label="id"
                                                   v-validate="'required'">
                                        </mg-select>
                                        <span class="text-danger text-sm " v-if="errors.has('date_year_from')">Dieses Feld ist ein Pflichtfeld</span>
                                    </vs-col>
                                </vs-row>
                            </vs-col>
                        </vs-row>

                        <vs-row class="mt-4" v-if="isInterval == true || isInterval == '1'">
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="3" class="pt-3">
                                <label>Zeitraum bis:</label>
                            </vs-col>
                            <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="9">
                                <vs-row>
                                    <vs-col  vs-w="6" >
                                        <mg-select v-model="data.date_month_to" :options="monthsOptions" name="date_month_to" data-vv-as="Zeitraum bis (Monat)"
                                                   placeholder="Monat" track-by="id" label="name"
                                                   v-validate="'required'">
                                        </mg-select>
                                        <span class="text-danger text-sm" v-if="errors.has('date_month_to')">Dieses Feld ist ein Pflichtfeld</span>
                                    </vs-col>
                                    <vs-col vs-w="6" class="pl-4">
                                        <mg-select v-model="data.date_year_to" :options="yearsOptions" name="date_year_to" data-vv-as="Zeitraum bis (Jahr)"
                                                   placeholder="Jahr" track-by="id" label="id"
                                                   v-validate="'required'">
                                        </mg-select>
                                        <span class="text-danger text-sm" v-if="errors.has('date_year_to')">Dieses Feld ist ein Pflichtfeld</span>
                                    </vs-col>
                                </vs-row>
                            </vs-col>
                        </vs-row>
                    </div>
                </div>


            </div>

            <div class="flex p-2 justify-end bg-gray-200 rounded-b">
                <vs-button color="danger" type="border" @click="onClose" class="mr-2">Abbrechen</vs-button>
                <vs-button v-if="!plannedSalesId " @click="createPlannedSale">Neuer Planumsatz hinzufügen</vs-button>
                <vs-button v-if="plannedSalesId" @click="editPlannedSale">Planumsatz ändern</vs-button>
            </div>
        </vs-popup>
    </div>
</template>

<script>
    import ApiService from "../../api";
    import staticOptions from "../../mixins/static/options";
    import MgSelect from "../../components/mg-select/MgSelect";
    import * as qs from "qs";
    import moment from 'moment';
    import PriceHelper from "../../mixins/helper/price.helper";
    import {mapGetters} from "vuex";

    export default {
        name: "planned-sales-modal",
        components: {
            MgSelect
        },
        props: {
            plannedSalesId: {
                type: Number,
                default: null
            },
            offerData: {
                default: null
            },
            active: {
                type: Boolean,
                default: false
            },
            disabledClientandOffer: {
                type: Boolean,
                default: false
            },
        },
        data() {
            return {
                data: [],
                clients: [],
                offers: [],
                isInterval: false,
                newPlannedSalesData: {
                    "client_id": null,
                    "order_id": null,
                    "order_item_id": null,
                    "title": "Rechnung",
                    "sum_netto": null,
                    "date_month_from": null,
                    "date_year_from": null,
                    "date_month_to": null,
                    "date_year_to": null,
                    "creator_user_id": null,
                },


            }
        },
        created() {
        },
        computed: {
            ...mapGetters(['userHasPermission', 'userId', 'user']),
            visible() {
                return this.active;
            },
            monthsOptions() {
                return staticOptions.months;
            },
            yearsOptions() {

                var date = new Date,
                    years = [],
                    year = date.getFullYear()-1;

                for (var i = year; i < year + 5; i++) {
                    years.push({id:i});
                }

                return years;
            },
        },
        methods: {
            openModal() {
                if (this.plannedSalesId == null) {
                    this.data = this.newPlannedSalesData
                    this.offers = []
                    var date = new Date
                    if(date.getMonth() >= 10) {
                        this.data.date_year_from =  date.getFullYear()+1
                        this.data.date_month_from =  date.getMonth()
                    } else {
                        this.data.date_year_from =  date.getFullYear()
                        this.data.date_month_from =  date.getMonth()
                    }
                    this.data.creator_user_id = this.userId

                } else {
                    this.data = []
                }

                this.fetchData();

            },
            fetchData() {
                this.$vs.loading();

                // if plannedSalesId == null, then its create, otherwise its edit
                if (this.plannedSalesId == null) {
                    return this.fetchClients().then((response) => {

                        if(this.offerData) {
                            this.data.client_id = this.offerData.client_id
                            this.data.order_id = this.offerData.id

                            this.fetchOffers({"id":this.offerData.client_id})
                            this.$vs.loading.close();
                        }
                        this.$vs.loading.close();
                    });
                } else {
                    this.fetchPlannedSale().then((response) => {

                            this.fetchClients().then((response) => {

                                this.fetchOffers({"id":this.data.client_id}).then((response) => {

                                    ApiService.get('clients/' + this.data.client_id)
                                        .then((response) => {
                                            if (response.data.status === 'success') {
                                                this.fetched = true;
                                                this.client = response.data.result;
                                            }

                                            this.$vs.loading.close();
                                        })
                                        .catch((error) => {
                                            this.$vs.loading.close();
                                        })
                                })
                            })

                        }
                    );

                }
                this.$vs.loading.close();
            },
            onClose() {
                this.$emit('update:active', false)
                this.$emit('close')
            },
            fetchPlannedSale(){
                return ApiService.get('order_planned_sales/' + this.plannedSalesId).then(response => {
                    response.data.result.date_year_from = parseInt(response.data.result.date_year_from)
                    response.data.result.date_year_to = parseInt(response.data.result.date_year_to)
                    this.data = response.data.result;

                })
            },
            fetchClients() {
                return ApiService.get(`clients`)
                    .then(response => {
                        if (response.status == 200) {
                            this.clients = response.data.result;
                        } else {
                            this.$vs.notify({
                                title: 'Fehler beim Kunden laden',
                                text: '',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            })
                        }
                    })
                    .catch((error) => {
                        this.$vs.notify({
                            title: 'Fehler beim Kunden laden',
                            text: '',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        })
                    });
            },
            fetchOffers(value) {
                let queryParams = {
                    filter: [
                        {
                            field: 'type.invoice_type',
                            expression: 'exact',
                            value: 'offer'
                        },
                        {
                            field: 'client_id',
                            expression: 'exact',
                            value: value.id
                        },
                        {
                            expression: 'group',
                            filter: [
                                {
                                    field: 'status',
                                    expression: 'exact',
                                    value: '16',
                                    operator: 'or',
                                },
                                {
                                    field: 'status',
                                    expression: 'exact',
                                    value: '18',
                                    operator: 'or',
                                }
                            ]
                        }
                    ]
                };

                return ApiService.get('orders', {
                    params: queryParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    this.offers = response.data.result;
                })
            },
            createPlannedSale() {

                if(this.isInterval == 1 || this.isInterval == true) {
                    return this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: "Das Monatliche Feature muss noch entwickelt werden. Bitte solang Einmalig verwenden",
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    });
                }

                this.$validator.validate().then(valid => {
                    if (valid) {

                        const data = this.data;
                        data.sum_netto = PriceHelper.parseCommaFloat(data.sum_netto.trim(data.sum_netto.replace("€","")));

                        ApiService.post('order_planned_sales', data).then(() => {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                title: 'Erfolgreich',
                                text: 'Der Planumsatz wurde erfolgreich erstellt.',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'success'
                            });

                            this.onClose()

                        }).catch((error) => {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                title: 'Fehler',
                                text: error.message,
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            })
                        });
                    } else {
                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: this.$validator.errors.all(),
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                    }
                })
            },
            editPlannedSale() {
                this.$validator.validate().then(valid => {
                    if (valid) {

                        const data = this.data;
                        data.sum_netto = PriceHelper.parseCommaFloat(data.sum_netto.trim(data.sum_netto.replace("€","")));

                        ApiService.put('order_planned_sales/'+this.plannedSalesId, data).then(() => {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                title: 'Erfolgreich',
                                text: 'Der Planumsatz wurde erfolgreich gespeichert.',
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'success'
                            });

                            this.onClose()

                        }).catch((error) => {
                            this.$vs.loading.close();
                            this.$vs.notify({
                                title: 'Fehler',
                                text: error.message,
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                                color: 'danger'
                            })
                        });
                    } else {
                        this.$vs.notify({
                            title: 'Fehlgeschlagen',
                            text: this.$validator.errors.all(),
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'danger'
                        });
                    }
                })
            },
            customClientLabel(option) {
                return `${option.customer_number} - ${option.company_name}`;
            },
            customOfferLabel(option) {
                return `${option.invoice_number} - ${option.title} - ${option.order_date}`;
            },
            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },

        },
        watch: {
            active: function (val) {
                if (val === true) {
                    this.openModal();
                }
            },
        }
    }
</script>

<style lang="scss">
    .planned-sales-modal--popup {
        .vs-popup {
            min-width: 700px;
            max-width: 100vw;
        }

        .vs-popup--content {
            padding: 5px 0 0 0;
            margin: 0;
            min-height: 60px;
            width: 100%;
        }
    }
</style>
