<template>
  <div v-if="userHasPermission('view_bank_transactions')">
    <vx-card class="h-full order-planned-sales pt-4 pb-8" v-if="userHasPermission('view_bank_transactions')">

      <div class="flex items-center justify-between px-6">
        <div class="mr-2">

          <label>Zeitraum:</label>
          <mg-select v-model="requestedData.selectedPeriod" :options="periodOptions" name="period_options"
                     data-vv-as="Zeitraum"
                     placeholder="Zeitraum" track-by="name" label="description">
          </mg-select>
        </div>
        <div class="mr-2">
          <label>Automatische Abrechnung filtern</label>
            <mg-select v-model="requestedData.isAutomaticAccountingActive" :options="automaticAccountingOptions" name="automaticAccountingOptions"
                       data-vv-as="Automatische Abrechnung aktiv"
                       :multiple="false"
                       placeholder="Automatische Abrechnung aktiv" track-by="value" label="name">
            </mg-select>

            <label>Einnahmen oder Ausgaben filtern</label>
            <mg-select v-model="requestedData.areRevenues" :options="areRevenuesOptions" name="areRevenues"
                       data-vv-as="Einnahmen oder Ausgaben anzeigen?"
                       :multiple="false"
                       placeholder="Einnahmen oder Ausgaben anzeigen?" track-by="value" label="name">
            </mg-select>

        </div>

        <div class="mr-2">
            <mg-select v-model="selectedContractTypeOption" :options="contractTypeOptions" name="selectedContractTypeOption" @select="setContractTypeOption" @remove="removeContractTypeOption"
                       data-vv-as="Vertragstyp"
                       :multiple="true"
                       placeholder="Vertragstyp" track-by="id" label="name">
            </mg-select>

        </div>
        <div class="mr-2 flex-">
          <vs-button color="primary" icon="refresh"  size="large" @click="fetchData">Daten laden</vs-button>
          <i v-if="inLoading"> Lädt noch</i>
        </div>
      </div>
    </vx-card>

    <div class="vx-row mb-base mt-4" v-if="userHasPermission('view_bank_transactions')">
      <div class="vx-col  w-full">

        <vs-alert color="danger" :active="true" icon="info" class="mb-4" v-show="!data.data">
          Keine Daten vorhanden, bitte Daten zuerst laden
        </vs-alert>

        <vx-card class="mb-2" v-if="data.data">

          <h2 class="mt-4 mb-2">Controlling</h2>



          <h2 class="mt-4">Monatsverlauf</h2>
          <div id="chart">
            <vue-apex-charts type="area" height="350" :options="chartOptions" :series="series"></vue-apex-charts>
          </div>
          <div class="chartSum mt-3">
            <label class="font-bold">Zahlen und Fakten</label>
            <span v-for="chartData in data.data" class="d-block">{{ chartData.name }}: {{
                parseCurrency(chartData)
              }}</span>

          </div>



          <template lang="html" v-if="data.otherData.productStatistic">
            <div>
              <vs-table search :data="data.otherData.productStatistic">
                <template slot="header">
                  <h3>
                    Produkte
                  </h3>
                </template>
                <template slot="thead">

                  <vs-th sort-key="name">
                    Produktname
                  </vs-th>
                  <vs-th sort-key="sum">
                    Summe
                  </vs-th>
                  <vs-th sort-key="sum">
                    Menge an Verträgen
                  </vs-th>
                </template>

                  <template slot-scope="{data}">
                    <vs-tr :key="indextr" v-for="(tr, indextr) in data" class="contract-name">
                      <vs-td :data="data[indextr].name">
                        {{data[indextr].name}}   <vs-icon icon="search"></vs-icon>
                          <div class="contract-list-container mt-2 mb-4">
                              <h4>Vertragsliste</h4>
                              <div class="contract-list">
                                  <ul>
                                      <li v-for="contract in data[indextr].contractList">
                                          {{contract.company_name}}  - ({{contract.sum|currency}}) (<router-link :to="'/contracts/'+contract.contract_id" target="_blank"  >Vertrag anzeigen</router-link>)
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </vs-td>

                      <vs-td :data="data[indextr].sum">
                        {{data[indextr].sum|currency}}
                      </vs-td>

                        <vs-td :data="data[indextr].total">
                            {{data[indextr].total}}
                        </vs-td>
                    </vs-tr>
                </template>
              </vs-table>
            </div>
          </template>




        </vx-card>

          <vx-card class="mt-3" v-if="data.otherData && data.otherData.contractsData">
              <h2 class="mt-4">Vertragsverlauf</h2>

              <div  v-for="(accountingDate, key) in data.otherData.contractsData" class="mb-3 mt-2">
                  <h3> {{ key|date}}</h3>
                  <div  v-for="(contract) in accountingDate" class="bg-blue-200 mb-2">
                      <b>{{contract.clientName}}</b> (Summe: {{contract.sum|currency}})
                      <ul>
                          <li v-for="item in contract.items"> {{item.product_name}} ({{item.amount}} x {{item.unit_price}})</li>
                      </ul>
                  </div>
              </div>
          </vx-card>
      </div>
    </div>


    <div v-if="!userHasPermission('view_bank_transactions')">
      Keine Berechtigung für diese Seite!
    </div>
  </div>
</template>

<script>
import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

import ApiService from "../../../api";
import moment from 'moment';
import QueryHelper from "../../../mixins/helper/query.helper";
import * as qs from "qs";
import PriceHelper from "../../../mixins/helper/price.helper";
import vSelect from 'vue-select'
import {mapGetters} from "vuex";
import GravatarImg from "vue-gravatar/src/components/GravatarImg";
import PlannedSalesModal from "../../../components/planned-sales/planned-sales-modal";
import PlannedSalesCalenderTable from "../../../components/planned-sales/planned-sales-calender-table";
import cloneDeep from "lodash/cloneDeep";
import VueApexCharts from 'vue-apexcharts'
import MgSelect from "../../../components/mg-select/MgSelect";

export default {
  components: {
    PlannedSalesModal,
    PlannedSalesCalenderTable,
    VueBootstrap4Table,
    'v-select': vSelect,
    GravatarImg,
    MgSelect,
    VueApexCharts
  },
  data() {
    return {
      // Edit/Create Modal
      selectedPlannedSalesId: null,
      plannedSalesModalActive: false,

      periodOptions: [
          {"name":"last_12months","description":"Letzte 12 Monate von heute"},
          {"name":"last_12months_from_last_month","description":"Letzte 12 Monate von letztem Monat"},
          {"name":"this_year","description":"Dieses Jahr"},
          {"name":"complete_last_year","description":"Vollständiges letztes Jahr (Jan-Dez)"},
          {"name":"complete_next_year","description":"Vollständiges nächstes Jahr (Jan-Dez)"},
          {"name":"next_12months","description":"Nächste 12 Monate von heute"},

      ],

        automaticAccountingOptions: [
            {"name":"Alles anzeigen","value":"null"},
            {"name":"Nur automatisch abrechenbare anzeigen","value":"1"},
            {"name":"Nur manuell/händisch abrechenbare anzeigen","value":"0"},
        ],

        areRevenuesOptions: [
            {"name":"Alles anzeigen","value":"null"},
            {"name":"Nur Einnahmen anzeigen","value":"1"},
            {"name":"Nur Ausgaben anzeigen","value":"0"},
        ],



      dataFetched: false,
      inLoading: false,
      inLoadingEmployee: false,
      data: [],
      employeesControllingData: [],
      offerData: [],
      requestedData: {
        MonthlyContractSum: true,
        selectedPeriod: "complete_next_year",
        contractTypeId: null,
        isAutomaticAccountingActive: "null",
        areRevenues: "null",
      },
      employeesOptions: [],
        contractTypeOptions: [],
        selectedContractTypeOption: [],
      employee: [],

      /*
        Chart
         */
      series: [],
      chartOptions: {
        chart: {
          height: 250,
          type: 'area'
        },
        tooltip: {},
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        labels: ["2"],
        xaxis: {
          type: 'string',
          categories: [],

        },

      },


      orderTypes: [],
    }
  },
  created() {
    this.fetchContractTypeData()
  },
  computed: {
    ...mapGetters(['userHasPermission', 'userId', 'user']),
  },
  methods: {
    async fetchData(initial = false) {

      if (this.userHasPermission('view_bank_transactions')) {
        this.inLoading = true
        //  this.$vs.loading();
        //  this.$vs.loading.close()
        ApiService.get('controlling/contract', {
          params: this.requestedData,
          paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
        }).then(response => {
          this.data = response.data.result;
          this.inLoading = false
          this.prepareChart();
          this.$vs.loading.close()
        })
      }

    },
      async fetchContractTypeData() {
        ApiService.get('contract_types').then(response => {

          this.contractTypeOptions = response.data.result;
          this.$vs.loading.close()
        })


    },
    fetchUsers() {
      ApiService.get('users').then(response => {
        if (response.data.status !== 'success') {
          return;
        }

        this.employeesOptions = response.data.result;
      })
    },
    prepareChart() {
      if (!this.data) {
        return;
      }

      // Chart 1 - Monthly
      this.series = []
      this.chartOptions.labels = [];
      var obj = [];

      this.chartOptions.labels = this.data.labels
      this.series = this.data.data

      this.chartOptions = {
        labels: this.data.labels
      };


    },
    setEmployeeChartOptions(response) {
      if (!response) {
        return;
      }

      // Chart 1 - Monthly
      this.employeeChartOptions.labels = [];
      this.employeeChartOptions.labels = response.data.result.labels
      this.chartOptions = {
        labels: response.data.result.labels
      };
    },
    parseCurrency(value) {
      if (value.currency != undefined && value.currency == false) {
        return value.total
      }
      return PriceHelper.formatCurrency(value.total)
    },
    floatPointToCommaCurrency(value) {
      return PriceHelper.numberWithCommas(value)
    },
    employeesPromise() {
      return ApiService.get('users');
    },
    onChangeQuery(queryParams) {
      this.queryParams = queryParams;
      this.fetchData();
    },
    onChangePerPage(value) {
      this.config.per_page = value;
    },
    openCreateModal() {
      this.selectedPlannedSalesId = null;
      this.plannedSalesModalActive = true;
    },
    closePlannedSalesModal() {
      this.plannedSalesModalActive = false;
      this.fetchData();
      this.$refs.calender.fetchData()
    },
    formatDateTime(value) {
      return moment(new Date(value)).format('DD.MM.YYYY')
    },
      setContractTypeOption(contractType){
        this.requestedData.contractTypeId = contractType.id
    },
      removeContractTypeOption(){
        this.requestedData.contractTypeId = null
    },
  },
    filters: {
        date(value) {
            return moment(value).format('DD.MM.YYYY');
        },
        dateTime(value) {
            return moment(value).format('DD.MM.YYYY HH:mm') + ' Uhr';
        }
    }
}
</script>

<style lang="scss">
.border-bottom {
  border-bottom: 1px solid rgb(174, 177, 180);
}
.multiselect__content-wrapper{
  z-index: 500000000000;
  position: absolute;
}
.contract-list-container {
  display: none;
}
.contract-name:hover .contract-list-container {
  display: block;
}
</style>
